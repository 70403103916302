<template>
  <v-container fluid style="margin-bottom: 100px">
    <v-row>
      <v-col cols="12">
        <div class="d-flex">
          <v-breadcrumbs :items="breadcumbs">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled" exact>
                {{ item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
          <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'"
            :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
              <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
              <v-layout column>
                <div>
                  <strong>{{ snackbar.title }}</strong>
                </div>
                <div>{{ snackbar.text }}</div>
              </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
              <v-icon>clear</v-icon>
            </v-btn>
          </v-snackbar>
          <v-spacer></v-spacer>
        </div>
      </v-col>
    </v-row>

    <!-- <v-container fluid class="d-flex flex-column" style="min-height: calc(100vh - 100px);"> -->
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card class="p-2" outlined>
          <v-container fluid>
            <v-row>
              <v-col class="col-12" sm="6" md="2" lg="2">
                <v-autocomplete solo clearable v-model="region" :items="regions" item-value="region_id"
                  item-text="descr" label="Region" class="ma-0 pa-0 border-12" hide-details=true
                  @change="(event) => handleRegionClick(event)"></v-autocomplete>
              </v-col>

              <v-col class="col-12" sm="6" md="2" lg="1">
                <v-autocomplete solo clearable v-model="subregion" :items="subregions" item-value="subregion_id"
                  item-text="descr" label="Sub Region" class="ma-0 pa-0 border-12" hide-details=true
                  @change="(event) => getOffice(event)"></v-autocomplete>
              </v-col>

              <v-col class="col-12" sm="6" md="2" lg="2">
                <v-autocomplete solo clearable v-model="office" :items="offices" item-value="office_id"
                  item-text="office" label="Office" class="ma-0 pa-0 border-12" hide-details=true></v-autocomplete>
              </v-col>
              <v-col class="col-12" sm="6" md="2" lg="6">
                <v-btn class="border-12" style="padding: 20px;" color="info" elevation="2" small
                  @click="getStoreVisit()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="12" xs="12" sm="12" md="12">
        <v-card outlined>
          <v-card-text>
            <!-- Chart Montly -->
            <p class="card-title">Monthly Visitor</p>
            <div id="monthlyChartContainer" style="height: 370px; width: 100%;"></div>
          </v-card-text>
        </v-card>

        <v-card outlined class="mt-2">
          <v-card-text>
            <!-- Chart Daily -->
            <p class="card-title">Daily Visitor</p>
            <div id="dailyChartContainer" style="height: 370px; width: 100%;"></div>
          </v-card-text>
        </v-card>

        <!-- dialog pendaftaran -->
        <v-dialog v-model="dialog_inv_payment" max-width="1000px">
          <v-card class="bg_card">
            <v-progress-linear :active="loading_inv_payment" :indeterminate="loading_inv_payment" absolute bottom
              color="deep-purple accent-4"></v-progress-linear>
            <v-card-title>Sales Detail</v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <!-- <v-card-title>Item Pendaftaran
                                  <v-spacer></v-spacer>
                                  <v-text-field
                                      v-model="$store.state.text"
                                      append-icon="mdi-magnify"
                                      label="Search..."
                                      single-line
                                      @keyup.enter="search_inv_payment"
                                      hide-details
                                  ></v-text-field>
                              </v-card-title> -->
                    <v-data-table dense :headers="header_item_inv_payments" :items="data_pendaftarans"
                      class="elevation-1 mt-2" :items-per-page="10" fixed-header :divider="true" :light="true">
                      <template v-slot:[`item.TglPesan`]="{ item }">
                        {{ (new Date(new Date(item.TglPesan) - (new Date()).getTimezoneOffset() *
                          60000)).toISOString().substr(0, 10) }}
                      </template>
                      <template v-slot:[`item.Lembar`]="{ item }">
                        {{ $store.getters.convertToCurrencyNoFrac(item.Lembar) }}
                      </template>
                      <template v-slot:[`item.HargaSatuan`]="{ item }">
                        {{ $store.getters.convertToCurrencyNoFrac(item.HargaSatuan) }}
                      </template>
                      <template v-slot:[`item.Total`]="{ item }">
                        {{ $store.getters.convertToCurrencyNoFrac(item.Total) }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close_inv_payment">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row class="mb-2">
      <v-col class="col-12" sm="12" md="3">
        <v-card outlined height="430" class="mt-2">
          <v-card-text>
            <!-- Chart Pie -->
            <p class="card-title">Category Visitor</p>
            <div id="pieChartContainer" style="height: 370px; width: 100%;"></div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12" sm="12" md="9">
        <v-card outlined class="mt-2">
          <v-data-table dense :headers="header_visitor_sum" :items="data_visitors" :items-per-page="10" fixed-header
            :divider="true" :light="true">
            <template v-slot:top>
              <v-toolbar flat color="white" class="mt-2 mb-2">
                <div class="d-flex w-100">
                  <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> Sum
                    Office
                  </h6>
                </div>
              </v-toolbar>
            </template>
            <template v-slot:[`item.total`]="{ item }">
              {{ $store.getters.convertToCurrencyNoFrac(item.total) }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon small class="mr-2" @click="detail(item)" v-on="on">
                      mdi-send
                    </v-icon>
                  </template>
                  <span>Detail</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-card outlined class="mt-2">
          <v-data-table dense :headers="header_item_inv_payments" :items="data_pendaftarans" :items-per-page="10"
            fixed-header :divider="true" :light="true">
            <template v-slot:top>
              <v-toolbar flat color="white" class="mt-2 mb-2">
                <div class="d-flex w-100">
                  <h6 class="indigo-text m-0 primary--text">
                    <v-icon color="primary" class="">mdi-table</v-icon> List Detail 
                  </h6>
                  <v-spacer></v-spacer>
                  <!-- button export excel -->
                  <v-btn v-if="data_pendaftarans.length" small color="success" style="padding: 17px;">
                    <v-icon>mdi-file-excel</v-icon>
                    <download-excel class="text" :fetch="exportExcel" :data="data_exports"
                      :before-generate="startDownload" :before-finish="finishDownload">
                      Export Excel
                    </download-excel>
                  </v-btn>
                </div>
              </v-toolbar>
            </template>
            <template v-slot:[`item.tr_date`]="{ item }">
              {{
                new Date(new Date(item.tr_date) - (new Date().getTimezoneOffset() * 60000))
                  .toISOString()
                  .replace('T', ' ')
                  .substr(0, 16)
              }}
            </template>
            <template v-slot:[`item.Lembar`]="{ item }">
              {{ $store.getters.convertToCurrencyNoFrac(item.Lembar) }}
            </template>
            <template v-slot:[`item.HargaSatuan`]="{ item }">
              {{ $store.getters.convertToCurrencyNoFrac(item.HargaSatuan) }}
            </template>
            <template v-slot:[`item.Total`]="{ item }">
              {{ $store.getters.convertToCurrencyNoFrac(item.Total) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-2" style="flex-grow: 1; height: 5%;">
      <v-col class="col-12" sm="6" md="12">
        <div style="height: 100%; width: 100%;"></div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import { kriMaster } from "@/backend-api/kri/kriMaster"
import { salesAnalysis } from "@/backend-api/kri/storeVisit"
import { backendApi } from "@/backend-api/backend-api-sr"
// import {converterLibrary} from "@/library/converterLibrary"

export default {
  data() {
    return {
      breadcumbs: [
        {
          text: 'Kencana Ritelindo Indonesia',
          disabled: false,
          href: '/admin/kri',
        },
        {
          text: 'Sales',
          disabled: true,
        },
        {
          text: 'Store Visit',
          disabled: true,
        }
      ],
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        title: null,
        visible: false
      },
      region: '',
      regions: [],
      subregion: '',
      subregions: [],
      office: '',
      offices: [],
      display: 'none',
      period_other_payment: '',
      dialog_other_payment: false,

      loading_other_payment: false,
      total_item_other_payment: 10,

      period_inv: '',
      dialog_inv_payment: false,
      pagination_inv_payment: 1,
      period: '',
      dialog_payment: false,
      pagination_payment: 1,
      loading_payment: false,

      option_item_payments: {},
      loading_inv_payment: false,

      total_item_inv_payments: 10,
      pagination_inv_payment: 1,
      header_item_inv_payments: [
        { text: 'Tanggal', value: 'tr_date' },
        { text: 'Customer Name', value: 'customer_name' },
        { text: 'Customer Address', value: 'customer_address' },
        { text: 'Type Visitor', align: 'start', sortable: true, value: 'visitor_str2' },
        { text: 'Phone', value: 'customer_phone' },
        { text: 'Activity', value: 'action_str2' },
        { text: 'Remark', value: 'remark' },
      ],
      data_pendaftarans: [],
      header_visitor_sum: [
        { text: 'OFFICE', value: 'office', width: '30%' },
        { text: 'OFF-BUY', value: 'off_buy', align: 'right' },
        { text: 'OFF-ASK PRICE', value: 'off_ask_price', align: 'right' },
        { text: 'OFF-ASK PROD', value: 'off_ask_prod', align: 'right' },
        { text: 'OL-BUY', value: 'ol_buy', align: 'right' },
        { text: 'OL-ASK PRICE', value: 'ol_ask_price', align: 'right' },
        { text: 'OL-ASK PROD', value: 'ol_ask_prod', align: 'right' },
        { text: 'TOTAL VISITOR', value: 'total_visitor', align: 'right' },
        { text: 'ACTION', value: 'actions', sortable: false, width: '10%' },
      ],
      headersColumn: {
        'Order No': 'CustomerOrderNo',
        'Tanggal Pesan': 'TglPesan',
        'Office': 'office',
        'Nama Barang': 'NamaBarang',
        'Qty': 'Lembar',
        'Harga': 'HargaSatuan',
        'Total': 'Total'
      },
      data_visitors: [],
      option_item_inv_payments: {},
      shop_summary_lastest: [],

      dialog_detail_paket: false,
      pagination_shop: 1,
      last_month: '',
      loading_shop_last_month: false,
      detail_shop_summary_lastest: [],
      total_item_shops: 10,
      option_item_shops: {},
      date_from: '',
      modal: false,
      date_to: "",
      modal_to: false,
      showDataKategori: true,
      showDataKomoditi: true,
      showDataKomoditiEksternal: true,
      showDataKomoditiInternal: true,
      showDataKomoditiJasa: true,
      dt_per_kategori: [],
      dt_per_komoditi: [],
      salesOriginal: [],
      returOriginal: [],
      tanggalTabel: '',
      tabelSales: true,
      selOff: '',
      data_exports: [],
      x: window.matchMedia("(max-width: 991px)")
    }
  },
  async mounted() {
    // this.getEnvConf()
    // this.getRegion()
    // this.getOffice()
    this.initialize()
    this.$store.dispatch('setOverlay', false)
    // this.searchSalesYear()
  },
  methods: {
    async getEnvConf() {
      // console.log('Cek group ID : '+this.$store.state.kmb.group_id);      
    },

    async initialize() {
      this.$store.dispatch('setOverlay', true)



      await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/VisitReport`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${this.$store.getters.isTokenUser}`
        }
      })
        .then(res => {
          if (res.data.region.length == 1) {
            this.regions = res.data.region.map(item => item.region);
            this.region = res.data.region[0].region
            this.region_disabled = true
          } else {
            this.regions = res.data.region.map(item => item.region);
            this.region_disabled = false
          }

          if (res.data.office.length == 1) {
            this.offices = res.data.office
            this.office = res.data.office[0].office_id
            this.office_disabled = true
          } else {
            this.offices = res.data.office
            this.office_disabled = false
          }

          // if(this.regions.length == 1) {
          //     this.region = this.regions[0].id
          //     this.region_disabled = true
          // }

          // if(this.offices.length == 1) {
          //     this.office = this.offices[0].id
          //     this.office = true
          // }

          this.$store.dispatch('setOverlay', false)
        })
        .catch(err => {
          this.$store.dispatch('setOverlay', false)
          if (err.response.status == '401') {
            this.$router.push('/');
          } else {
            Swal.fire({
              text: err.response.data.data,
              icon: 'error',
            })
          }
        })
    },

    async getStoreVisit() {
      this.$store.dispatch('setOverlay', true);

      let reqBody = {
        'period_type': this.periode,
        'region': this.region,
        'subregion': this.subregion,
        'office_id': this.office,
      }

      const respData = await salesAnalysis.getDataMonthly('', reqBody);
      this.$store.dispatch('setOverlay', false);

      if (respData.status != 200) {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle",
          mode: "multi-line",
          position: "top",
          timeout: 7500,
          title: "Warning",
          text: 'Failed to retrieve data',
          visible: true
        };

        return;
      }

      this.getChartMonthly(respData.data.data.last ?? [], respData.data.data.current ?? []);

      // -- clear data
      this.data_visitors = [];
      this.data_pendaftarans = [];
      // this.showDataKategori = false;

      const data1 = {
        type: "column",
        click: this.getOfficeVisitor,
        name: "Daily Visitor",
        indexLabel: "{y}",
        indexLabelFontSize: 14,
        showInLegend: true,
        indexLabelPlacement: "outside",
        indexLabelOrientation: "horizontal",
        color: "#298edb",
      }

      // -- clear chart
      this.getChartDaily([], 'dailyChartContainer', '', '', data1);
      this.getPieChart([], 'pieChartContainer', '', '', '')
    },

    async getDailyVisitor(e) {
      // -- clear data
      this.$store.dispatch('setOverlay', true)
      this.data_pendaftarans = [];


      let reqBody = {
        'period_type': this.periode,
        'region': this.region,
        'subregion': this.subregion,
        'office_id': this.office,
        'period': e.dataPoint.period ?? '',
      }

      const respData = await salesAnalysis.getData('', reqBody)
      this.$store.dispatch('setOverlay', false)

      if (respData.status != 200) {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle",
          mode: "multi-line",
          position: "top",
          timeout: 7500,
          title: "Warning",
          text: 'Failed to retrieve data',
          visible: true
        };

        return;
      }

      let datas = respData.data.data

      let category = datas.category
      category.forEach(item => {
        item.y = parseInt(parseFloat(item.y).toFixed(2));
      });

      let calendars = datas.calendars
      calendars.forEach(item => {
        item.x = parseInt(item.label)
        item.y = Math.floor(item.y)
      });

      // -- chart pie
      this.showDataKategori = false
      this.getPieChart(category, 'pieChartContainer', '', '', '')

      const data1 = {
        type: "column",
        click: this.getOfficeVisitor,
        name: "Daily Visitor",
        indexLabel: "{y}",
        indexLabelFontSize: 14,
        showInLegend: true,
        indexLabelPlacement: "outside",
        indexLabelOrientation: "horizontal",
        color: "#298edb",
      }
      
      // -- chart daily visitor
      this.getChartDaily(calendars, 'dailyChartContainer', '', '', data1);
      this.getOfficeVisitor({
        dataPoint: {
          label: e.dataPoint.period ?? ''
        }
      });
    },

    async getOfficeVisitor(event) {
      // -- clear data
      this.$store.dispatch('setOverlay', true)
      this.data_pendaftarans = [];

      this.tanggalTabel = event.dataPoint.label
      this.tabelSales = true
      let reqBody = {
        'period_type': this.periode,
        'region': this.region,
        'subregion': this.subregion,
        'office_id': this.office,
        'period': this.tanggalTabel
      }

      const respData = await salesAnalysis.sumOfficeVisit('', reqBody)
      this.$store.dispatch('setOverlay', false)

      if (respData.status === 200) {
        this.data_visitors = respData.data.data
      } else {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle",
          mode: "multi-line",
          position: "top",
          timeout: 7500,
          title: "Warning",
          text: 'Failed to retrieve data',
          visible: true
        };
      }
    },

    // async onClick2(event){
    //   this.$store.dispatch('setOverlay', true)
    //   this.tanggalTabel = event.dataPoint.label
    //   this.tabelSales = false
    //   let reqBody = {
    //       'period_type': this.periode,
    //       'region' : this.region,
    //       'subregion' : '',
    //       'office_id' : this.office,
    //       'date_from' : this.tanggalTabel
    //   }
    //   const respData = await salesAnalysis.getSumOfficeSales('', reqBody)
    //   if (respData.status === 200) {
    //     this.data_sales = respData.data.data
    //     this.$store.dispatch('setOverlay', false)
    //   } else {
    //     this.snackbar = {
    //         color: "error",
    //         icon: "mdi-alert-circle",
    //         mode: "multi-line",
    //         position: "top",
    //         timeout: 7500,
    //         title: "Warning",
    //         text: 'Failed to retrieve data',
    //         visible: true
    //     };
    //     this.$store.dispatch('setOverlay', false)
    //   }
    // },

    async detail(event) {
      this.selOff = event.office_id
      let reqBody = {
        'period_type': this.periode,
        'region': this.region,
        'subregion': this.subregion,
        'office_id': this.selOff,
        'period': this.tanggalTabel
      }

      if (this.tabelSales) {
        const respData = await salesAnalysis.detailOfficeVisit('', reqBody)
        if (respData.status === 200) {
          this.data_pendaftarans = respData.data.data
          this.$store.dispatch('setOverlay', false)
        } else {
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Warning",
            text: 'Failed to retrieve data',
            visible: true
          };
          this.$store.dispatch('setOverlay', false)
        }
      } else {
        const respData = await salesAnalysis.detailOfficeVisit('', reqBody)
        if (respData.status === 200) {
          this.data_pendaftarans = respData.data.data
          this.$store.dispatch('setOverlay', false)
        } else {
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Warning",
            text: 'Failed to retrieve data',
            visible: true
          };
          this.$store.dispatch('setOverlay', false)
        }
      }
    },

    async exportExcel() {
      await axios.post(`${process.env.VUE_APP_URL}/api/kri/sales/storeVisit/getExportExcel`, {
        'region': this.region,
        'subregion': this.subregion,
        'office_id': this.selOff,
        'period': this.tanggalTabel,
      }, {
        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
      })
        .then(res => {
          this.data_exports = res.data.data
        })

      return this.data_exports
    },

    startDownload() {
      this.$store.dispatch('setOverlay', true)
    },
    finishDownload() {
      this.$store.dispatch('setOverlay', false)
    },

    handleRegionClick() {
      this.getOffice()
      this.getSubRegion()
    },

    async getOffice() {
      var urlFilter = '?'
      if (this.region != '' && this.region != null) {
        urlFilter = `${urlFilter}region_id=${this.region}`
      }


      this.offices = []
      await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/getOffice`, {
        region_id: this.region ? this.region : "",
        subregion: this.subregion ? this.subregion : ""
      }, {
        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
      })
        .then(res => {
          this.offices = res.data.data
        })
    },

    async getSubRegion() {
      var urlFilter = '?'
      if (this.region != '' && this.region != null) {
        urlFilter = `${urlFilter}region_id=${this.region}`
      }


      this.subregions = []
      await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/getSubRegion`, {
        region: this.region ? this.region : "",
        subregion: this.subregion ? this.subregion : ""
      }, {
        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
      })
        .then(res => {
          this.subregions = res.data.data
        })
    },

    async getRegion() {
      // var respData = await kriMaster.getRegion(``, null, false, false, false)
      // if (respData.status === 200) {
      //     this.regions = respData.data.data
      // }
      this.regions = []
      await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/getRegion`, {

      }, {
        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
      })
        .then(res => {
          this.regions = res.data.data
        })
    },

    close_inv_payment() {
      this.dialog_inv_payment = false
    },

    addSymbols(e) {
      var suffixes = ["", "K", "M", "B"];
      var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

      if (order > suffixes.length - 1)
        order = suffixes.length - 1;

      var suffix = suffixes[order];
      return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
    },
    toggleDataSeries(e) {
      if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      } else {
        e.dataSeries.visible = true;
      }
      e.chart.render();
    },
    toggleDataPointVisibility(e) {
      if (e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
        e.dataPoint.y = e.dataPoint.actualYValue;
        e.dataPoint.actualYValue = null;
        e.dataPoint.indexLabelFontSize = null;
        e.dataPoint.indexLabelLineThickness = null;
        e.dataPoint.legendMarkerType = "circle";
      }
      else {
        e.dataPoint.actualYValue = e.dataPoint.y;
        e.dataPoint.y = 0;
        e.dataPoint.indexLabelFontSize = 0;
        e.dataPoint.indexLabelLineThickness = 0;
        e.dataPoint.legendMarkerType = "cross";
      }
      e.chart.render();
    },
    showDefaultText(chart, text) {
      var dataPoints = chart.options.data[0].dataPoints;
      var isEmpty = !(dataPoints && dataPoints.length > 0);

      if (!isEmpty) {
        for (var i = 0; i < dataPoints.length; i++) {
          isEmpty = !dataPoints[i].y;
          if (!isEmpty)
            break;
        }
      }

      if (!chart.options.subtitles)
        chart.options.subtitles = [];
      if (isEmpty) {
        chart.options.subtitles.push({
          text: text,
          verticalAlign: 'center',
        });
        chart.options.data[0].showInLegend = false;
      } else {
        chart.options.data[0].showInLegend = true;
      }
    },

    getPieChart(dt_detail, container_detail, title_detail, label_detail, labelNoData) {
      var pie_chart = new CanvasJS.Chart(container_detail, {
        animationEnabled: true,
        exportEnabled: true,
        theme: "light2",
        exportEnabled: true,
        title: {
          text: title_detail,
          fontFamily: "Calibri",
          fontSize: 20
        },
        exportEnabled: true,
        subtitles: [
          {
            text: label_detail,
            //Uncomment properties below to see how they behave
            fontFamily: "Calibri",
            fontColor: "red",
            fontSize: 12
          }
        ],
        legend: {
          itemclick: this.toggleDataPointVisibility
        },
        data: [{

          type: "pie",
          percentFormatString: "#0.##",
          indexLabel: "{label} #percent%",
          indexLabelFontSize: 12,

        }]
      });
      pie_chart.options.data[0].dataPoints = dt_detail;
      this.showDefaultText(pie_chart, labelNoData);

      if (this.x.matches) {

        for (var i = 0; i < pie_chart.options.data.length; i++) {
          pie_chart.options.data[i].indexLabelFontSize = 6;
        }
        pie_chart.render();
      }
      pie_chart.render();
    },

    getChartDaily(dt1, container, title, label, data1) {
      var chart = new CanvasJS.Chart(container, {
        animationEnabled: true,
        theme: "light2",
        exportEnabled: true,
        title: {
          text: title,
          fontFamily: "Calibri",
          fontSize: 20
        },
        exportEnabled: true,
        subtitles: [
          {
            text: label,
            //Uncomment properties below to see how they behave
            fontFamily: "Calibri",
            fontColor: "red",
            fontSize: 12
          }
        ],
        axisX: {
          interval: 1,  // Paksa setiap label muncul
          labelAngle: -45, // Miringkan label agar tidak bertumpuk,
          labelFormatter: function (e) {
            return dt1.some(d => d.x === e.value) ? e.value : "";
          }
        },
        axisY: {
          crosshair: {
            enabled: true,
            snapToDataPoint: true
          },
          labelFormatter: this.addSymbols,
        },
        toolTip: {
          shared: true
        },
        legend: {
          cursor: "pointer",
          itemclick: this.toggleDataSeries,
          fontSize: 11
        },
        data: [data1]
      });

      chart.options.data[0].dataPoints = dt1;
      // chart.options.data[1].dataPoints = dt2;
      this.showDefaultText(chart, "");
      var x = window.matchMedia("(max-width: 991px)");
      if (x.matches) {

        for (var i = 0; i < chart.options.data.length; i++) {
          chart.options.data[i].indexLabelFontSize = 8;
        }
        chart.render();
      }
      chart.render();
    },

    getChartMonthly(data1, data2) {
      var chart1 = new CanvasJS.Chart("monthlyChartContainer", {
        animationEnabled: true,
        theme: "light2",
        axisY: {
          titleFontSize: 24
        },
        data: [{
          type: "column",
          indexLabel: "{y}",
          name: "Last Year",
          showInLegend: true,
          indexLabelPlacement: "inside",
          indexLabelOrientation: "vertical",
          color: "#f79b07",
          yValueFormatString: "###,###",
          dataPoints: data1.map((e) => ({
            label: e.label,
            period: `${e.year}${e.month}`,
            y: parseInt(e.y)
          })),
          click: this.getDailyVisitor,
        },
        {
          type: "column",
          indexLabel: "{y}",
          name: "This Year",
          showInLegend: true,
          indexLabelPlacement: "inside",
          indexLabelOrientation: "vertical",
          color: "#07dbf7",
          yValueFormatString: "###,###",
          dataPoints: data2.map((e) => ({
            label: e.label,
            period: `${e.year}${e.month}`,
            y: parseInt(e.y)
          })),
          click: this.getDailyVisitor,
        }]
      })
      chart1.render()
    },
  },
  watch: {
    option_item_other_payments: {
      handler() {
        // this.loading_other_payment = true
        const { page, itemsPerPage } = this.option_item_other_payments
        if (this.period_other_payment) {
          this.getItemOtherPayment(page, itemsPerPage, this.period_other_payment)
        }
      },
      deep: true,
    },
    option_item_payments: {
      handler() {
        // this.loading_payment = true
        const { page, itemsPerPage } = this.option_item_payments
        if (this.period) {
          this.getItemPayment(page, itemsPerPage, this.period)
        }
      },
      deep: true,
    },

    option_item_shops: {
      handler() {
        // this.loading_shop_last_month = true
        const { page, itemsPerPage } = this.option_item_shops
        if (this.last_month) {
          this.getItemShop(page, itemsPerPage, this.last_month)
        }
      },
      deep: true,
    },
  }
}
</script>