import {backendApi} from "../backend-api-sr"

export const salesAnalysis = (() =>{
    
    const getData = ( async (subPath, body) => {
        var url = '/api/kri/sales/storeVisit/summaryDailyVisit'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    const getDataMonthly = ( async (subPath, body) => {
        var url = '/api/kri/sales/storeVisit/summaryVisit'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    const sumOfficeVisit = ( async (subPath, body) => {
        var url = '/api/kri/sales/storeVisit/sumOfficeVisit'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    const detailOfficeVisit = ( async (subPath, body) => {
        var url = '/api/kri/sales/storeVisit/detailOfficeVisit'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    return {getData, getDataMonthly, sumOfficeVisit, detailOfficeVisit};
})()